<template>
  <v-dialog v-model="programDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="green" dark v-bind="attrs" v-on="on"  icon  elevation="2"
        ><v-icon>mdi-calendar-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
        <v-card-title>
            New Program
        </v-card-title>
      <v-card-text style="height: 100px">
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                label="Program Name"
                v-model="form.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="addProgram"> Save </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="programDialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {  sync,dispatch} from "vuex-pathify";

export default {
  name: "ProgramDialog",
  data() {
    return {
      form:{
        name: '',
      }
    };
  },

  computed: {
    ...sync("program", ["programDialog","program"]),
  },

  
  methods: {
    addProgram(){
        dispatch('program/addProgram',this.form)
        this.programDialog=false
    }
  },
};
</script>